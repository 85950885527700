import React from 'react'
import { RouteProps as RouteRRDProps } from 'react-router-dom'
import AuthLayer from './AuthLayer'

interface RouteProps extends RouteRRDProps {
  component: React.ComponentType<React.PropsWithChildren<unknown>>
  layout: React.ComponentType<React.PropsWithChildren<unknown>>
  hasPublicAccess?: boolean
}

const RouteElement: React.FC<React.PropsWithChildren<RouteProps>> = ({
  component: Component,
  layout: Layout,
  hasPublicAccess,
}) => {
  return (
    <AuthLayer hasPublicAccess={hasPublicAccess}>
      <Layout>
        <Component />
      </Layout>
    </AuthLayer>
  )
}

export default RouteElement
